import React, { useEffect, useState, useRef } from "react";
import { Trans, I18nextContext, Link } from 'gatsby-plugin-react-i18next';
import Photo from "../components/Photo";
import "bootstrap/dist/css/bootstrap.min.css";
import Form from "react-bootstrap/Form";
import "../styles/purchase.scss";
import "../styles/freetrial.scss";
import "../styles/common_style.scss";
import { navigate } from "gatsby";
import Modal from 'react-modal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import {
    confirmRegistration, resendOTP, confirmTrialUserRegistration,
    getLoginUserPoolInformation
} from "../services/tenant-identity-service";
import 'react-phone-input-2/lib/style.css';
import { Helmet } from "react-helmet";
import { MESSAGE } from '../constant/message-enum';
import { getMessages, getValueMessages } from '../services/language-service';
import * as uuid from "uuid";
import { CustomStorage } from '../custom-storage';
import { AuthenticationDetails, CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import { deleteCookie, deleteCognitoTokenFromLocalStorage, setCookie } from "../services/cookie-storage-service";
import awsPoolConfiguration from "../aws-exports";
import { Auth, Amplify } from "aws-amplify";

const GATSBY_LANDING_PAGE_URL = process.env.GATSBY_LANDING_PAGE_URL;
const GATSBY_LOGIPULSE_APPLICATION_URL = process.env.GATSBY_LOGIPULSE_APPLICATION_URL;

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        // width: '800px',
        // padding: '48px'
    }
};

const alertBoxStyle = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '500px',
        // padding: '48px',
    }
};

const initialFormValue = {
    fullName: "",
    companyName: "",
    countryGuid: uuid.NIL,
    email: "",
    mobileNo: "",
    password: "",
    otp: ""
}

function TrialOTPVerification({ location }) {
    const trialRegisterationData = location.state;

    const { language } = React.useContext(I18nextContext);
    const buildCanonicalUrl = () =>
        language === "en"
            ? GATSBY_LANDING_PAGE_URL + "/otp-verification/"

            : GATSBY_LANDING_PAGE_URL + `/${language}/otp-verification/`;

    const canonicalUrl = buildCanonicalUrl();

    const buildHrefUrl = () =>
        language === "en"
            ? JSON.parse(JSON.stringify(GATSBY_LANDING_PAGE_URL + "/"))
            : JSON.parse(JSON.stringify(GATSBY_LANDING_PAGE_URL + `/${language}/`))
    const hrefUrl = buildHrefUrl();

    const { t } = useTranslation();

    const [alert_message, setAlertMessage] = useState('');
    const [formValues, setformValues] = useState(initialFormValue);
    const [isLoading, setLoading] = useState(false);
    const [isModalOpen, setModelOpen] = useState(false);
    const [checked, setCheckBoxValue] = useState(false);
    const [isAlertModalOpen, setAlertModelOpen] = useState(false);
    const otpRef = useRef(null);

    const handleAlertModalClose = async (event) => {
        setAlertModelOpen(false);
    }

    const initializeFormValues = () => {
        let formValue = Object.assign(initialFormValue, trialRegisterationData);
        setformValues(formValue);
        setAutoFocusForOTP();
    }

    useEffect(() => {
        initializeFormValues();

        if (!trialRegisterationData) {
            let currentLanguage = `${language}`;
            if (currentLanguage === 'en') {
                navigate('/');
            }
            else {
                navigate(`/${currentLanguage}/`);
            }
        }

        return () => {
        };
    }, [language]);

    const handleChange = (event) => {
        const value = { ...formValues };
        value[event.target.name] = event.target.value;
        setformValues(value);
    }

    const clearAlertType = async () => {
        setAlertMessage("");
    }

    const submitHandler = async (event) => {
        event.preventDefault();
        await clearAlertType();
        if (!checked) {
            setAlertMessage("You must agree with terms and conditions.");
            setAlertModelOpen(true);
        } else {
            await confirmOTP();
        }
    }

    const handleModalOpen = event => {
        setModelOpen(true);
    }

    const handleModalClose = event => {
        setModelOpen(false);
    }

    const handleCheck = () => {
        setCheckBoxValue(!checked);
    }

    const setAutoFocusForOTP = () => {
        if (otpRef.current) {
            otpRef.current.focus();
        }
    }

    const confirmOTP = async () => {
        setLoading(true);
        let inputObj = {
            "email": formValues.email,
            "confirmationCode": formValues.otp
        }

        confirmRegistration(inputObj).then(async (res) => {
            setLoading(false);
            await confirmTrialUserRegisterationProcess()
        }).catch(err => {
            setLoading(false);
            let errMessage = err.response.data.errorMessage;
            setAlertMessage(errMessage);
            setAlertModelOpen(true);
            setAutoFocusForOTP();
        });
    }

    const resendConfirmationCode = (event) => {
        event.preventDefault();
        setLoading(true);
        let currentLanguage = `${language}`;

        let inputData = {
            "email": formValues.email
        }

        resendOTP(inputData).then(async (res) => {
            let valueFields = { V1: formValues.email };
            let validationMessage = await getValueMessages(MESSAGE.OTP_SENT_TO_MAIL, currentLanguage, JSON.stringify(valueFields));
            await setAlertMessage(validationMessage);
            await setAlertModelOpen(true);
            setLoading(false);
        }).catch(err => {
            let errMessage = err.response.data.errorMessage;
            setAlertMessage(errMessage);
            setAlertModelOpen(true);
            setLoading(false);
        });
    }

    const confirmTrialUserRegisterationProcess = async () => {
        setLoading(true);
        let inputObj = {
            "email": formValues.email
        }

        confirmTrialUserRegistration(inputObj).then(async (res) => {
            setLoading(false);
            await readUserPoolDataAndLoginUserToApplication();
        }).catch(async (err) => {
            setLoading(false);
            let errorMessage = err.response.data.errorMessage;
            setAlertMessage(errorMessage);
            setAlertModelOpen(true);
        });
    }

    const readUserPoolDataAndLoginUserToApplication = async () => {
        try {
            let currentLanguage = `${language}`;
            setLoading(true);
            await deleteCognitoTokenFromLocalStorage();
            await deleteCookie();
            let { email } = formValues;
            let userResult = await getLoginUserPoolInformation(email);

            if (Array.isArray(userResult) && userResult.length > 0) {
                let userObj = userResult[0];
                await authenticateUserCredential(userObj);
            } else {
                let validationMessage = await getMessages(MESSAGE.USER_DOES_NOT_EXIST, currentLanguage);
                setAlertMessage(validationMessage);
                setAlertModelOpen(true);
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            let errMessage = error.response.data.errorMessage;
            setAlertMessage(errMessage);
            setAlertModelOpen(true);
        }
    }

    const authenticateUserCredential = async (userResult) => {
        try {
            let { Tenant } = userResult;
            let { email, password } = formValues;
            let { clientId, userPoolId } = Tenant;

            let authenticationDetails = new AuthenticationDetails({
                Username: email,
                Password: password,
            });

            let poolData = {
                UserPoolId: userPoolId,
                ClientId: clientId
            };

            let userPool = new CognitoUserPool(poolData);
            let userData = {
                Username: email,
                Pool: userPool,
                Storage: CustomStorage
            };

            var cognitoUser = new CognitoUser(userData);

            cognitoUser.authenticateUser(authenticationDetails, {
                onSuccess: async (session) => {
                    await setCookieValue();
                    await configureUserPool(Tenant);
                    await redirectApplication();
                    await setLoading(false);
                },
                onFailure: async (err) => {
                    setLoading(false);
                    setAlertModelOpen(true);
                    setAlertMessage(err.message);
                }
            });
        } catch (error) {
            let errorMessage = error.hasOwnProperty('errorMessage') ? error.errorMessage : error.message;
            setAlertModelOpen(true);
            setAlertMessage(errorMessage);
            setLoading(false);
        }
    }

    const configureUserPool = async (tenantData) => {
        let { region, identityPoolId, userPoolId, clientId } = tenantData;

        awsPoolConfiguration.aws_project_region = region;
        awsPoolConfiguration.aws_cognito_identity_pool_id = identityPoolId;
        awsPoolConfiguration.aws_cognito_region = region;
        awsPoolConfiguration.aws_user_pools_id = userPoolId;
        awsPoolConfiguration.aws_user_pools_web_client_id = clientId;

        Amplify.configure(awsPoolConfiguration);
        Auth.configure(awsPoolConfiguration);

        await Auth.currentSession()
            .then(async (res) => {
            })
            .catch((error) => {
                let errorMessage = error.hasOwnProperty('errorMessage') ? error.errorMessage : error.message;
                setAlertModelOpen(true);
                setAlertMessage(errorMessage);
                setLoading(false);
            });
    }

    const setCookieValue = async () => {
        let currentLanguage = `${language}`;
        setCookie("isSignIn", true);
        setCookie("isLoginIn", true);
        setCookie("currentLanguage", currentLanguage);
    }

    const redirectApplication = async () => {
        window.location.replace(GATSBY_LOGIPULSE_APPLICATION_URL + "/logipulse/mail-inbox/inbox");
    }

    return (
        <div>
            <Helmet>
                <title>
                    {t("Logipulse – Free-Trial")}
                </title>
                <Link rel="canonical" href={canonicalUrl} />
                <Link rel="alternate" href={hrefUrl} hreflang={language} />

            </Helmet>
            <main className="purchase-page freetrial_page_main" lang={language}>
                <div className="purchase-page-div freetrial_page_main_div" lang={language}>
                    <div className="container">
                        <div className="row purchase_freetrial freetrial_content_row_div padding_tb_45" lang={language}>
                            <div className="col-md-6">
                                <div className="contents purchasecontents">
                                    <Link to="/" language={language}>{" "}<Photo src="LogiPulse-Logo-H48.svg" className="photo-size" alt="" />{" "}</Link>
                                    <p className="padding_bottom_unset"><Trans>Here starts your novel opportunity to lead the competition.</Trans><Trans>Logipulse renders you a digital transformation to your Logistics operation.</Trans><Trans>Spread out your wings around the world and manage them from a single platform.</Trans><Trans>Head out of adversity!</Trans></p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="contents">
                                    <div className="purchase_heading_section">
                                        <div className="profile">
                                            <b><Trans>Sign Up</Trans></b>
                                        </div>
                                        <div className="purchase_progress">
                                            <ul id="progressbar_purchase">
                                                <li></li>
                                                <li className="active"></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <fieldset disabled={isLoading}>
                                        <Form onSubmit={submitHandler} autoComplete="off">
                                            <div className="resend_btn_txt">
                                                <div>
                                                    <Link to="#" className="resend_otps" onClick={resendConfirmationCode}><Trans>Resend OTP</Trans></Link>
                                                </div>
                                            </div>

                                            <div className="otp_form_group_arr">
                                                <Form.Group controlId="formBasicOtp">
                                                    <Form.Label className="otp_content"><Trans>OTP</Trans></Form.Label>
                                                    <Form.Control
                                                        type="text" name="otp"
                                                        value={formValues.otp}
                                                        ref={otpRef}
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="margin_bottom_16 otp_message_text">
                                                <Trans>OTP_MESSAGE</Trans>
                                            </div>
                                            <div id="main" lang={language}>
                                                <div className="form-group form-check terms_condition_button" lang={language}>
                                                    <div className="chkbox-agree-section" lang={language}>
                                                        <input type="checkbox" className="form-check-inputs" onChange={handleCheck} defaultChecked={checked} />

                                                        <div className="agree-section" lang={language}>
                                                            <span><Trans>I agree</Trans> <Link to="#" onClick={handleModalOpen}><Trans>Terms & Conditions</Trans></Link></span>
                                                        </div>
                                                    </div>
                                                    <div className="align-btn">
                                                        <Form.Group className="next_button_align">
                                                            <button type="submit" className="trial_submit_button font_12 muller_medium display_flex flex_align_center justify_center pointer position_relative" disabled={isLoading}><Trans>Submit</Trans>
                                                                {
                                                                    isLoading ? (
                                                                        <Photo src="loader_64.gif" className="spinner" alt="" />
                                                                    ) : null
                                                                }
                                                            </button>
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    </fieldset>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal
                    isOpen={isModalOpen}
                    ariaHideApp={false}
                    onRequestClose={handleModalClose}
                    contentLabel="Example Modal In Gatsby"
                    style={customStyles} className="modal_padding"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <div className="modal-header">
                        <h5 className="modal-title ml-0">Terms & Conditions</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleModalClose}>
                            <span aria-hidden="true" className="imges"> <Photo src="close-black.svg" alt="" /></span>
                        </button>
                    </div>
                    <div className="modal-body text-left content_scroll height_400">
                        <div className="termscondition"><b>USE OF SITE</b></div>
                        <div className="termscondition_content">The contents of this Site are the property of STELLOSYS or are licensed to STELLOSYS by a third party. Except as stated above, or unless otherwise expressly stated, no license is granted herein expressly, implied or otherwise under any patent, trademark, copyright or other intellectual property of STELLOSYS or any third party.</div>
                        <div className="termscondition"><b>ACCESS AND INTERFERENCE</b></div>
                        <div className="termscondition_content">The contents of this Site are the property of STELLOSYS or are licensed to STELLOSYS by a third party. Except as stated above, or unless otherwise expressly stated, no license is granted herein expressly, implied or otherwise under any patent, trademark, copyright or other intellectual property of STELLOSYS or any third party.</div>
                        <div className="termscondition"><b>NON-DISCLOSURE</b></div>
                        <div className="termscondition_content">Unless otherwise agreed, each contracting party will treat any information (including documentation, samples etc.) disclosed to it by STELLOSYS as a trade secret, use such information solely for the purpose of the Contract, and refrain from disclosing it to any third parties.</div>
                        <div className="termscondition"><b>LIMITATION OF LIABILITY</b></div>
                        <div className="termscondition_content">Except for actions or claims arising from the gross negligence or intentional or wilful misconduct, liability to STELLOSYS shall be limited to the compensation fees based upon the contract.</div>
                        <div className="termscondition"><b>LINKS TO THIRD PARTY SITES</b></div>
                        <div className="termscondition_content">STELLOSYS does not review or control and is thus not responsible for any third-party sites or their content. Such links should not be assumed to constitute an endorsement or recommendation by STELLOSYS. By clicking on any of these links, you are leaving the STELLOSYS Site and accessing these other sites at your own risk.</div>

                        <div className="termscondition"><b>COMMUNITY FORUMS</b></div>
                        <div className="termscondition_content">STELLOSYS may provide chat room, bulletin boards, blogs or other user forums (“Forums”) that allow third parties to post content and comments. STELLOSYS has the right, but not the obligation, to review or monitor third postings (“Submissions”) and to remove Submissions that contains material STELLOSYS deems offensive, abusive, defamatory, obscene or otherwise unacceptable. However, STELLOSYS shall not be liable for claims arising from those Submissions whether arising under the laws of copyright, libel, privacy, obscenity or otherwise.</div>

                        <div className="termscondition"><b>GOVERNING LAW</b></div>
                        <div className="termscondition_content">These Terms shall be governed in all respects by the laws of the <b>The Cybersecurity Framework (NCFS)</b></div>

                        <div className="termscondition"><b>IMPORT/EXPORT</b></div>
                        <div className="termscondition_content">You may not access, download, use, or export information (including software, products or services) contained on this Site in violation of any applicable laws or regulations including, but not limited to the export laws of the European Union and United States.</div>

                        <div className="termscondition"><b>PRIVACY</b></div>
                        <div className="termscondition_content">For information on how STELLOSYS handles your personal information, see our Privacy Policy.</div>

                        <div className="termscondition"><b>INDEMNIFICATION</b></div>
                        <div className="termscondition_content">You agree to indemnify, defend and hold harmless, STELLOSYS, its subsidiaries, affiliates, joint ventures, business partners, licensors, employees, agents and third-party information providers from and against all losses, expenses, damages and costs, including attorney fees, resulting from any violation of these Terms (including negligent or wrongful conduct) by you or your use and access to the Site.</div>



                    </div>
                    <div className="modal-footer">
                        <div className="learnmore"><FontAwesomeIcon icon={faExclamationCircle} /><span className="learnmore-text">Learn more</span></div>
                        <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={handleModalClose}>I AGREED!</button>
                    </div>

                </Modal >

                <Modal
                    isOpen={isAlertModalOpen}
                    ariaHideApp={false}
                    onRequestClose={handleAlertModalClose}
                    contentLabel="Example Modal In Gatsby"
                    style={alertBoxStyle} className="modal_padding"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <div className="modal-body">
                        <div className="termscondition_content pb-0">{alert_message}</div>
                    </div>
                    <div className="modal-footer text_align_center pl-0 pr-0 mt-0 justify_space_around">
                        <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={handleAlertModalClose}>OK</button>
                    </div>

                </Modal >

                <footer className="purchase-footer">
                    <div className="purchase-footer-bottom">
                        <div className="container">
                            <div className="row footer_row_editor align-content-center">
                                <div className="col-md-6 power">
                                    <div className="powered_by_font_style"><Trans>Powered by</Trans></div>
                                    <Photo src="Stellosysyw-Logo.svg" alt="" className="powered_logo" />
                                </div>
                                <div className="col-md-6 copyright copyright_flex_align">
                                    <div className="copyright_font_style">
                                        <Trans>Copyright 2021 Stellosys</Trans><br /> <Trans>All rights Reserved.</Trans>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </main>
        </div>
    )
}

export default TrialOTPVerification;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
